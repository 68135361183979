import slider1 from '../assets/images/slider1.jpg';
import slider2 from '../assets/images/slider2.jpg';
import slider3 from '../assets/images/slider3.jpg';
const s1=process.env.REACT_APP_API_URL+'/products/image/slider1.webp';
const s2=process.env.REACT_APP_API_URL+'/products/image/slider2.webp';
const s3=process.env.REACT_APP_API_URL+'/products/image/slider3.webp';
console.log("s1",s1);

export const sliderInfo = [
	{
		img: s1,
		title: 'Va asteptam cu drag la',
		desc: 'Pizzeria Clinceni',
		subDesc: 'Pentru a savura cele mai delicioase preparate culinare',
	},

	{
		img: s2,
		title: 'Gusturi Speciale',
		desc: 'Dragoste la început',
		subDesc: 'mușcă',
	},

	{
		img: s3,
		title: 'MÂNCAREA BUNĂ ESTE DISPOZIȚIE BUNĂ',
		desc: 'Burta conduce',
		subDesc: 'mintea',
	},
];
