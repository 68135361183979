import { Box,Grid } from '@mui/material';
import Footer from 'components/Layout/Footer/Footer';
import Header from 'components/Layout/Header/Header';
import React from 'react';
import BannerPages from './components/BannerPages';
import PagesContent from './components/PagesContent';

export default function Pages() {
	return (
		<div className="App">
			<Header />
			<BannerPages />
			<Box m="20px">
			<Grid container maxWidth="lg" sx={{ margin: '0 auto' }}>
				<Grid item>
					<PagesContent />
				</Grid>
			</Grid>
			</Box>
			<Footer />
		</div>
	);
}
