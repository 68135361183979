import Box from '@mui/material/Box';
//import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
//import { reviewInfos } from 'constants/ReviewInfos';
import * as React from 'react';
import HTMLFlipBook from "react-pageflip";
//import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";

import cover from "assets/images/menu/menu-clinceni_Page_01.jpg";
import P1 from "assets/images/menu/menu-clinceni_Page_02.jpg";
import P2 from "assets/images/menu/menu-clinceni_Page_03.jpg";
import P3 from "assets/images/menu/menu-clinceni_Page_04.jpg";
import P4 from "assets/images/menu/menu-clinceni_Page_05.jpg";
import P5 from "assets/images/menu/menu-clinceni_Page_06.jpg";
import P6 from "assets/images/menu/menu-clinceni_Page_07.jpg";
import P7 from "assets/images/menu/menu-clinceni_Page_08.jpg";
import P8 from "assets/images/menu/menu-clinceni_Page_09.jpg";
import P9 from "assets/images/menu/menu-clinceni_Page_10.jpg";
import P10 from "assets/images/menu/menu-clinceni_Page_11.jpg";

//import { pdfjs, Document, Page as ReactPdfPage  } from 'react-pdf/dist/esm/entry.webpack5';


//import samplePDF from "./menu-clinceni.pdf";

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const pages = [P1, P2, P3, P4, P5, P6, P7, P8, P9, P10];
//const width = 600;
//const height = 833;

/*const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  );
});*/

const useStyles = makeStyles({
	typoHeading: {
		textAlign: 'center',
	},

	review_box: {
		display: 'flex',
		margin: '60px 0',
		boxShadow: '0 3px 13px 1px rgb(0 0 0 / 9%) !important',
	},

	box_content: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		margin: 'auto',
		'& .MuiTypography-h4': {
			color: 'rgba(0, 0, 0, 0.72) !important',
		},

		'& .MuiTypography-h5': {
			color: 'rgba(0, 0, 0, 0.72) !important',
			margin: '12px 0 4px 0',
		},

		'& .MuiTypography-body1': {
			color: 'rgba(0, 0, 0, 0.72) !important',
			fontWeight: '500',
			padding: '0 16px',
		},
	},
});

export default function ReviewContent() {
	const classes = useStyles();

	return (
		<Box maxWidth="lg" sx={{ margin: '60px auto' }} >
			<Typography
				className={classes.typoHeading}
				variant="h1"
				sx={{ fontSize: { md: '5rem', xs: '3rem' }, fontWeight: { md: '400', xs: '600' } }}
			>
				Pizzeria Restaurant Clinceni
			</Typography>
				<Box className={classes.box_content} >
						<CardContent display="flex"
      justifycontent="center">
      <div className="book">
        <HTMLFlipBook
          width={675}
          height={953}
	  minWidth={400}
          maxWidth={675}
          minHeight={566}
          maxHeight={953}
          showCover={true}
          maxShadowOpacity={0.5}
          mobileScrollSupport={true}
	  className="flip-book html-book demo-book"
          style={{ background: "lavender" }}
        >
          <div className="demoPage">
            <img src={cover} alt="cover" />
          </div>
          {pages.map((page, i) => (
            <div className="demoPage" key={i}>
              <img src={page} alt={i} />
            </div>
          ))}
        </HTMLFlipBook>
      </div>
						</CardContent>

					</Box>

				


		</Box>
	);
}
