import React, { useState, useEffect } from 'react';
import foodApi from 'api/foodApi';
import { Link } from 'react-router-dom';
import { homeCategoryData } from 'constants/categorySliderInfo';

// material ui
import { Button, Container } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

// swiper js
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// swiper scss
import 'swiper/swiper.scss';

import 'assets/styles/_typography.scss';
import './styles.scss';

// swiper modules
SwiperCore.use([Autoplay, Navigation]);

function CategorySlider() {
const [categories, setCategories] = useState(homeCategoryData);
//setCategories(homeCategoryData);
let categ=[];
useEffect(() => {

	//	CategoriesService.getAllwithproducts().then(x => setCategories(x.data));
		 //categ  =
		  foodApi.getFoods('/categories/slider').then(x => setCategories(x));
		 //,setCategories(categ));
	//	if(categ) { 
	//	setCategories(categ);
//}
		//dispatch(shopFoodAction.fetchBurgersFood(burgersFood),);
	//	console.log("categ:",categ);
		//console.log("categ 1:",categ);
		//setCategories(categ);
		console.log("categories: ",categories);
		//if(categ.length) { 
			//setCategories(categ);
		//	console.log("categoriesn 2: ",categories);
	//}
	   //      dispatch(categoriesActions.getAll());
	   // //console.log(categories.data.data);
	   }, []);

	return (
		<section className="home-category">
			<Container>
				<div className="home-category__container">
					<div className="primary-yellow-text">Ce avem?</div>
					<h2 className="primary-heading-text">Răsfoiți produsele noastre</h2>
					<div className="home-category__cards">
						<Swiper
							slidesPerView={2}
							loop
							loopFillGroupWithBlank={true}
							autoplay={{
								delay: 1800,
								disableOnInteraction: false,
							}}
							navigation={{
								prevEl: '.prev-btn',
								nextEl: '.next-btn',
							}}
							breakpoints={{
								600: {
									slidesPerView: 4,
								},
								960: {
									slidesPerView: 7,
								},
							}}
						>
							{categories.map(({ image, title, slug }, index) => (
								<SwiperSlide key={index}>
									<div className="home-category__card">
										<div className="home-category__card-img-wrapper">
										<Link to={"menu/"+slug}>
											<img className="home-category__card-img" src={process.env.REACT_APP_API_URL + "/products/image/"+image} alt="Category card" />
											</Link>
										</div>
										<Link to={"menu/"+slug}>
										<div className="home-category__card-description">
										
										{title}
										
										</div>
										</Link>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
						<Button className="prev-btn">
							<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />
						</Button>
						<Button className="next-btn">
							<DoubleArrowIcon />
						</Button>
					</div>
				</div>
			</Container>
		</section>
	);
}

export default CategorySlider;
