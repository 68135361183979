import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RoomIcon from '@mui/icons-material/Room';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import { Box, Pagination } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EmptyImg from 'assets/images/empty-shop.e78970f0.svg';
import ToastBody from 'components/CustomToast/ToastBody';
import { selectFoodFilter, shopFoodAction } from 'features/ShopFood/shopFoodSlice';
import { Food } from 'models';
import React, {  useState } from 'react'
// lazy load img js
import { LazyLoadImage } from 'react-lazy-load-image-component';
// lazy load img css
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';
import foodApi from 'api/foodApi';
import './ShopProduct.scss';
import DOMPurify from 'dompurify';
/*import {
	isContains,
	checkLocalStorage,
	removeLocalCart,
	saveLocalCart
  } from 'features/Cart/localStorage';*/

export interface ShopProductProps {
	bestFood: Food[];
	getFoodById: (id: string) => void;
}

function ShopProduct({ bestFood, getFoodById }: ShopProductProps) {
	const dispatch = useAppDispatch();

	// const loading = useAppSelector(selectFoodLoading);
	const filter = useAppSelector(selectFoodFilter);
	//const [carts, setCarts] = useState<Food[]>(checkLocalStorage());
	//console.log("carts",carts);
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		dispatch(
			shopFoodAction.setFilter({
				...filter,
				_page: value,
			})
		);
	};

	//React toastify
	const showToast = () => {
		return toast(
			<ToastBody title="Success !" desc="Produsul a fost adăugat în coș" icon={true} />,
			{
				position: 'top-left',
				className: 'background__toast-success',
				closeButton: (
					<div
						style={{
							position: 'absolute',
							top: 8,
							right: 8,
							color: '#fff',
						}}
					>
						<ExitToAppIcon sx={{ width: '2rem !important', height: '2rem !important' }} />
					</div>
				),
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			}
		);
	};

	const handleGetFoodById = async (id: string, idx: number) => {
		const listFoodById = await foodApi.getFoodById(id);
console.log("listFoodById",id, listFoodById[0].price, listFoodById[0].title, listFoodById[0]);
		dispatch(shopFoodAction.getFoodById(listFoodById[0]));
		dispatch(shopFoodAction.getIdFood(idx));
		showToast();
		/*saveLocalCart(listFoodById[0]);
		const card = listFoodById[0];
		(card: Food) => {
			setCarts(carts =>
			  isContains(carts, card)
				? carts.map(cart =>
					cart.id === card.id
					  ? { ...card, count: cart.count && cart.count + 1 }
					  : cart
				  )
				: [...carts, { ...card, count: 1 }]
			)
	  
			saveLocalCart(card)
		 };*/
		// console.log("carts 2",carts);

		
	};
	const getText = (html) =>{
		const doc = new DOMParser().parseFromString(html, "text/html")
		return doc.body.textContent
	  };

	return (
		<Box>
			{/* {loading && (
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<CircularProgress
						sx={{
							color: '#ff514e',
						}}
					/>
				</Box>
			)} */}
			{bestFood.length > 0 ? (
				<div>
					<div className="shop-product">
						{bestFood.map((food, idx) => (
							<div key={idx} className="shop-product_box">
								<div className="shop-product_box-main" onClick={() => getFoodById(food.slug)}>
									<div className="shop-product__img-wrapper">
										<LazyLoadImage
											effect="blur"
											src={process.env.REACT_APP_API_URL + "/products/image/"+food.image}
											className="shop-product__img"
											alt=""
											width="100%"
											height="100%"
										></LazyLoadImage>
										<div className="shop-product__rate">
											<StarIcon />
											<span>{food.rate}</span>
										</div>
									</div>

									<div className="shop-product__content">
										<div className="shop-product__name">{food.title}</div>
										<p className="shop-product__description">
											{getText(food.description.substring(0, 60))}...
											</p>
										<div className="shop-product__row">
											<div className="shop-product__location">
												<RoomIcon />
												
											</div>
											<div className="shop-product__price">{`${food.price}Ron`}</div>
										</div>
									</div>
								</div>

								<div className="shop-product__btns">
									<div className="shop-product__btn">
										<FavoriteBorderIcon />
									</div>
									<div
										className="shop-product__btn"
										onClick={() => handleGetFoodById(food.id, idx)}
									>
										<ShoppingCartIcon />
									</div>
								</div>
								<div className="shop-product__label">Favorit</div>
							</div>
						))}
					</div>
					<Pagination count={Math.trunc((bestFood.length/16)+1)} page={filter._page} onChange={handleChange} />
				</div>
			) : (
				<div className="shop-product__empty">
					<img src={EmptyImg} alt="Empty Food" />
					<div>Nu există niciun produs pe care îl căutați</div>
				</div>
			)}
		</Box>
	);
}

export default ShopProduct;
