import { Food } from 'models/food';

export const checkLocalStorage = () => {
  const carts = localStorage.getItem('carts')
  if (carts !== null) {
    return JSON.parse(carts)
  }

  return []
}

export const isContains = (carts: Food[], cart: Food) =>
  carts.some(c => c.id === cart.id)

export const saveLocalCart = (cart: Food) => {
  let carts = checkLocalStorage() as Food[]
  isContains(carts, cart)
    ? (carts = carts.map(c =>
        c.id === cart.id ? { ...c, count: c.count && c.count + 1 } : c
      ))
    : carts.push({ ...cart, count: 1 })

  localStorage.setItem('carts', JSON.stringify(carts))
}

export const removeLocalCart = (cart: Food) => {
  let carts = checkLocalStorage() as Food[]
  carts = carts.filter(c => c.id !== cart[0].id)
//console.log("delete", carts[0].id, cart[0].id)
  localStorage.setItem('carts', JSON.stringify(carts))
  //console.log("delete", carts[0].id, cart[0].id, cart)
}
