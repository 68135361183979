import React from 'react';
import ReactDOM from 'react-dom';
//import ReactDOM from "react-dom/client";
//import { createRoot } from 'react-dom/client';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import GlobalStyles from './components/common/GlobalStyles/GlobalStyles';
import { ToastContainer } from 'react-toastify';

/*const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
		<Provider store={store}>
			<GlobalStyles>
				<App />
			</GlobalStyles>
			<ToastContainer />
		</Provider>
  </React.StrictMode>
);*/
//const container = document.getElementById('root');
//const root = createRoot(container);
if (process.env.REACT_APP_ENV !== "development") {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}


/*root.render(
    <React.StrictMode>
		<Provider store={store}>
			<GlobalStyles>
				<App />
			</GlobalStyles>
			<ToastContainer />
		</Provider>
    </React.StrictMode>
);*/
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<GlobalStyles>
				<App />
			</GlobalStyles>
			<ToastContainer />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
