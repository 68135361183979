/** @format */

import NotFound from 'components/common/NotFound/NotFound';
import HomePage from 'components/HomePage/HomePage';
import Review from 'components/Review';
import Pages from 'components/Pages';
import LoginPage from 'features/Auth/Login/Login';
import Cart from 'features/Cart/Cart';
import PopularFood from 'features/PopularFood';
import CategoryFood from 'features/CategoryFood';
import ShowFoodById from 'features/ShopFood/pages/ShowFoodById';
import React from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import ScrollToTop from 'utils/ScrollToTop';
import CookieConsent, { Cookies } from "react-cookie-consent";

function App() {
	return (
		<>
			<Router>
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/shop" element={<ShowFoodById />}>
					        <Route path=":id" element={<Outlet />} />
						</Route>
						<Route path="/popular-food" element={<PopularFood />} />
						<Route path="/menu/:id" element={<CategoryFood/>} />
					        <Route path="/page/:id" element={<Pages/>} />
						<Route path="/review" element={<Review />} />
						<Route path="/despre" element={<Review />} />
						<Route path="/login" element={<LoginPage />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</ScrollToTop>
				<Cart />




		<CookieConsent
enableDeclineButton 
  location="bottom"
  buttonText="Am inteles"
  declineButtonText="Refuza"
  cookieName="myAwesomeCookieName2"

  style={{ background: "rgba(67, 69, 67, 0.5)" }}
  buttonStyle={{
    background:  "rgb(228, 226, 215)",
  
    fontWeight: "bolder",

  }}

  expires={150}
>
 Acest site web folosește cookie-uri pentru a îmbunătăți experiența utilizatorului.{" "}.
               <span > <a href="/page/politica-de-cookie-uri" style={{color:'white'}}>Politica de cookie-uri</a></span>
            

</CookieConsent>
			</Router>
		</>
	);
}

export default App;
