import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from 'app/hooks';
import { detailTableData } from 'constants/DataTableProduct';
import React, { useState } from 'react';
import { selectListComment } from './DetailTabSlice';
import DetailTabComment from './pages/DetailTabComment/DetailComment';
import './styles.scss';
import DOMPurify from 'dompurify';

const useStyles = makeStyles({
	buttonTab: {
		fontSize: '1.4rem !important',
		color: 'rgba(0, 0, 0, 0.84) !important',
		backgroundColor: 'unset !important',
		boxShadow: 'unset !important',
	},

	active: {
		fontSize: '1.4rem !important',
		color: '#fff !important',
		backgroundColor: '#ff514e !important',
		'&:hover': {},
	},
});

export default function DetailTab({dsc}) {
	const classes = useStyles();

	const [isShow, setIsShow] = useState(true);

	const userAvatar = localStorage.getItem('userImg');
	const imgFb = localStorage.getItem('imgFb');

	const comments = useAppSelector(selectListComment);

	const handleChangeTabOne = () => {
		setIsShow(true);
	};

	const handleChangeTabTwo = () => {
		setIsShow(false);
	};
	const getText = (html) =>{
		const doc = new DOMParser().parseFromString(html, "text/html")
		return doc.body.textContent
	  };

	return (
		<div className="detail-tab">
			<div className="detail-tab__btns">
				<div className="detail-tab__btn">
					<Button
						className={isShow ? classes.active : classes.buttonTab}
						onClick={handleChangeTabOne}
						variant="contained"
					>
						Descriere
					</Button>
				</div>

				<div className="detail-tab__btn">
					<Button
						className={!isShow ? classes.active : classes.buttonTab}
						onClick={handleChangeTabTwo}
						variant="contained"
					>
						Cometarii{userAvatar || imgFb ? `(${comments.length})` : ''}
					</Button>
				</div>
				<div className="detail-tab__btn-background"></div>
			</div>
			{isShow ? (
				<div className="detail-tab__content">
					<div className="detail-tab__content-description">
					<p
								  dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(dsc),
								  }}
								></p>

					</div>


				</div>
			) : (
				<DetailTabComment />
			)}
		</div>
	);
}
