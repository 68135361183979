import Beef from 'assets/images/beef.95b6ea9e.svg';
import Chicken from 'assets/images/chicken.0bf00278.svg';
import Coffee from 'assets/images/coffee.06d317df.svg';
import Dinner from 'assets/images/dinner.5e12f363.svg';
import Burger from 'assets/images/burgerBreakfast.svg';
import Juice from 'assets/images/juice.dd087137.svg';
import Lunch from 'assets/images/lunch.68d27597.svg';
import Pork from 'assets/images/pork.98f99006.svg';
import Tea from 'assets/images/tea.591a2362.svg';

const homeCategoryData = [
	{
		image: Burger,
		title: 'mic dejun',
	},
	{
		image: Coffee,
		title: 'cafea',
	},
	{
		image: Pork,
		title: 'sunca de porc',
	},
	{
		image: Dinner,
		title: 'cina',
	},
	{
		image: Tea,
		title: 'ceai',
	},
	{
		image: Lunch,
		title: 'pranz',
	},
	{
		image: Juice,
		title: 'suc',
	},
	{
		image: Chicken,
		title: 'pui la gratar',
	},
	{
		image: Beef,
		title: 'friptura de vita',
	},
];

export { homeCategoryData };
