import React, { useState, useEffect } from 'react';
import Bread from 'assets/images/bread.svg';
import Burger from 'assets/images/burger.svg';
import Drinks from 'assets/images/drinks.svg';
import Pizza from 'assets/images/pizza.svg';
import Sandwich from 'assets/images/sandwich.svg';


import foodApi from 'api/foodApi';
//icon food
export { Bread, Burger, Drinks, Pizza, Sandwich };


export const TypeOptions = () => {
	const [categories, setCategories] = useState(null);	
useEffect(() => {
//const [categories, setCategories] = useState(null);
let categories=[];
		foodApi.getFoods('/categories/main').then(x => setCategories(x));
		console.log("filtru categ:",categories);
	   }, []);
	   let top  = [
		{
			image: Burger,
			title: 'Produse Noi',
		},
		{
			image: Bread,
			title: 'Paste',
		},
		{
			image: Sandwich,
			title: 'Desert',
		},
		{
			image: Drinks,
			title: 'Băuturi',
		},
		{
			image: Pizza,
			title: 'Pizza',
		},
	];
	console.log("top1",top);
	if(categories) {
		top = categories ;
	   }
	   console.log("top2",top);
	   return top;
	   };




//export const typeOptions = MainFood();


//options checkbox food
export const optionCheckbox = ['Sub 100 Ron', 'Peste 100 Ron'];
