import Footer from 'components/Layout/Footer/Footer';
import Header from 'components/Layout/Header/Header';
import ShopFood from 'features/ShopFood/index';
import React, {  useEffect, useState } from 'react';
import BannerCategoryFood from './pages/BannerCategoryFood/BannerCategoryFood';
import { useParams  } from 'react-router-dom';
import foodApi from 'api/foodApi';

export default function CategoryFood() {
	const routeParams = useParams();
	const [categm, setCategm] = useState(null);
	//const { name } = useParams();
	console.log("routeParams", routeParams.id);
	useEffect(() => {
console.log("routeParams id", routeParams.id);
		foodApi.getFoods('/categories/slug/'+routeParams.id).then(x => setCategm(x.data));
		console.log("Menu categ:",categm);
	   }, [routeParams.id]);
	   if(categm) {
	console.log("Menu1 categ:",categm.id);
	}
	return (
		<div> 
			<Header />
			<BannerCategoryFood />
			{routeParams.id &&
			<div style={{ padding: '60px 0' }}>
				<ShopFood catid={routeParams.id}/>
			</div>
			}
			<Footer />
			
		</div>
	);
}
