import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartActions, selectShowCart } from './CartSlice';
import { makeStyles } from '@mui/styles';
import { selectFoodById, shopFoodAction } from 'features/ShopFood/shopFoodSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmptyCart from 'assets/images/empty_cart.png';
import DialogComponent from 'components/common/Dialog';
import './styles.scss';
import {
	isContains,
	checkLocalStorage,
	removeLocalCart,
	saveLocalCart
  } from './localStorage';
  import { Food } from 'models';

const useStyles = makeStyles({
	headingCart: {
		fontWeight: '600 !important',
		color: 'rgba(0, 0, 0, 0.84) !important',
		margin: '0 !important',
		textTransform: 'uppercase',
	},

	buttonClose: {
		color: '#ff514e !important',
		fontSize: '3rem !important',
		cursor: 'pointer'
	},

	boxFood: {
		display: 'flex',
		marginTop: '20px',

		'& img': {
			width: '80px',
			height: '40px',
		},
	},

	boxTitle: {
		paddingLeft: '10px',
		'& .MuiTypography-h5': {
			fontSize: '1.7rem !important',
			lineHeight: '2rem !important',
			color: 'rgba(0, 0, 0, 0.84) !important',
		},
		'& .MuiTypography-body1': {
			color: '#ff514e !important',
			fontSize: '1.4rem !important',
			lineHeight: '1.5rem !important',
			fontWeight: '500 !important',
			marginTop: '2px',
		},
		'& .MuiTypography-body2': {
			fontSize: '1.3rem !important',
			lineHeight: '1.4rem !important',
			color: 'rgba(7, 6, 6, 0.84) !important',
			marginTop: '16px !important',
		},
	},

	itemDelete: {
		margin: 'auto 0 auto auto',
		cursor: 'pointer',
		'& .MuiSvgIcon-root': {
			color: '#b2bec3 !important',
			width: '2.2rem !important',
			height: '2.2rem !important',
		},
	},

	buttonCheckout: {
		display: 'flex !important',
		fontSize: '1.5rem !important',
		color: '#fff !important',
		backgroundColor: '#ff514e !important',
		borderRadius: '4px !important',
		margin: '40px auto 0 auto !important',
	},

	emptyCart: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '40px',
		'& .MuiButton-root': {
			fontSize: '1.5rem !important',
			color: '#fff !important',
			backgroundColor: '#ff514e !important',
			width: '200px !important',
			borderRadius: '4px !important',
		},
	},

	cartOverlay: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundColor: 'rgba(0,0,0,.65) !important',
		zIndex: '124',
	},
});

export default function Cart() {
	const [isShow, setIsShow] = useState(false);
	const userAvatar = localStorage.getItem('userImg');
	const imgFb = localStorage.getItem('imgFb');
	const [carts, setCarts] = useState<Food[]>(checkLocalStorage());
	console.log("cos carts", carts, carts.length);
	const [total, setTotal] = useState(0); 
	/* setTotal ( carts.reduce(
		(sum, { price, count }) => sum + price * (count || 0),
		0
	  ));*/
	  console.log("total :", total);
	// const ref = useRef<any>([]);

	const navigate = useNavigate();
	const classes = useStyles();

	const dispatch = useAppDispatch();
	const isShowCart = useAppSelector(selectShowCart);
	const foodById = useAppSelector(selectFoodById);
	useEffect(() => {
		setCarts(checkLocalStorage());
		//const [categories, setCategories] = useState(null);
		setTotal ( carts.reduce(
			(sum, { price, count }) => sum + price * (count || 0),
			0
		  ));
			   }, [foodById]);
	//console.log("foodById cos", foodById);//,foodById[0].product
/*if(carts.length) {
	isShowCart= true;
}*/
	const handleHideCart = () => {
		dispatch(cartActions.handleShowCart(false));
	};

	const handleShowCartModal = () => {
		dispatch(cartActions.handleShowCart(false));
	};

	const handleRedirectBuyNow = () => {
		navigate('/popular-food');
		dispatch(cartActions.handleShowCart(false));
	};

	const handleShowDialog = () => {
		if (!userAvatar && !imgFb) {
			setIsShow(true);
		} else {
			setIsShow(false);
		}
		dispatch(cartActions.handleShowCart(false));
	};

	const handleRemoveFoodById = (id: string) => {
		dispatch(shopFoodAction.RemoveFoodById(id));
		const cart = carts.filter(c => c.id === id);
		console.log("cart", cart);
		setCarts(carts => carts.filter(c => c.id !== id));
		//localStorage.setItem('carts', JSON.stringify(carts));
      removeLocalCart(cart);
	};

	return (
		<>
			{isShowCart && (
				<div className="cart__box">
					<Box>
						<Box>
							<Grid container alignItems="center" sx={{ p: 1.6 }}>
								<Grid item xs>
									<Typography
										className={classes.headingCart}
										gutterBottom
										variant="h4"
										component="div"
										sx={{ fontSize: { md: '2.6rem', xs: '2rem' } }}
									>
										Coșul dvs
									</Typography>
								</Grid>
								<Grid item>
									<CancelPresentationIcon
										className={classes.buttonClose}
										onClick={handleHideCart}
									/>
								</Grid>
							</Grid>
							<Divider />
							<div className="cart__box-main">
								{carts.map((food: any, idx: number) => (
									<Box key={idx} className={classes.boxFood} sx={{ px: 1.6 }}>
										<img src={process.env.REACT_APP_API_URL + "/products/image/"+food.image} alt={food.title} />
										<Box className={classes.boxTitle}>
											<Typography variant="h5">{food.title}<span> x{food.count}</span></Typography>
											<Typography variant="body1">{`${food.price} Ron`}</Typography>
										</Box>
										<Box
											className={classes.itemDelete}
											onClick={() => handleRemoveFoodById(food.id)}
										>
											<DeleteIcon />
										</Box>
									</Box>
								))}
								<Divider />
								<Typography
	                                    align="center"
										gutterBottom
										variant="h5"
										sx={{ width: { md: '400px', xs: '280px', color: 'rgba(0, 0, 0, 0.7) !important' } }}
									>
										{`Total : ${total} Ron`}
									</Typography>
							</div>
							{carts.length <= 0 && (
								<Box className={classes.emptyCart}>
									<img className="cart__box-empty__img" src={EmptyCart} alt="Empty Cart" />
									<Typography
										variant="h4"
										sx={{
											fontSize: '2rem !important',
											fontWeight: '500',
											color: 'rgba(0, 0, 0, 0.7) !important',
										}}
									>
										Coșul dvs. este gol
									</Typography>
									<Button
										variant="contained"
										onClick={handleRedirectBuyNow}
										sx={{ marginTop: { md: '16px', xs: '24px' } }}
									>
										Cumpără acum
									</Button>
								</Box>
							)}
						</Box>
					</Box>
					{carts.length > 0 && (
						<Button
							onClick={handleShowDialog}
							fullWidth
							className={classes.buttonCheckout}
							variant="contained"
							sx={{ width: { md: '400px', xs: '280px' } }}
						>
							Comanda
						</Button>
					)}
				</div>
			)}
			<DialogComponent isShow={isShow} setIsShow={setIsShow} />

			{isShowCart && <Box className={classes.cartOverlay} onClick={handleShowCartModal}></Box>}
		</>
	);
}
