import Box from '@mui/material/Box';
//import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
//import { reviewInfos } from 'constants/ReviewInfos';
import React, {  useEffect, useState } from 'react';
import { useParams  } from 'react-router-dom';
import foodApi from 'api/foodApi';
import DOMPurify from 'dompurify';
//import HTMLFlipBook from "react-pageflip";
//import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";



//import { pdfjs, Document, Page as ReactPdfPage  } from 'react-pdf/dist/esm/entry.webpack5';


//import samplePDF from "./menu-clinceni.pdf";

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//const pages = [P1, P2, P3, P4, P5, P6, P7, P8, P9, P10];
//const width = 600;
//const height = 833;

/*const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  );
});*/

const useStyles = makeStyles({
	typoHeading: {
		textAlign: 'center',
	},

	review_box: {
		display: 'flex',
		margin: '60px 0',
		boxShadow: '0 3px 13px 1px rgb(0 0 0 / 9%) !important',
	},

	box_content: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: 12,
		margin: 'auto',
		'& .MuiTypography-h4': {
			color: 'rgba(0, 0, 0, 0.72) !important',
		},

		'& .MuiTypography-h5': {
			color: 'rgba(0, 0, 0, 0.72) !important',
			margin: '12px 0 4px 0',
		},

		'& .MuiTypography-body1': {
			color: 'rgba(0, 0, 0, 0.72) !important',
			fontWeight: '500',
			padding: '0 16px',
		},
	},
});

export default function PagesContent() {
	const classes = useStyles();
	const routeParams = useParams();
	const [pag, setPag] = useState(null);
	useEffect(() => {
console.log("routeParams id", routeParams.id);
	const cucu = foodApi.getFoods('/pages/slug/'+routeParams.id).then(x => setPag(x.data));
	console.log("cucu",cucu);
		
	   }, [routeParams.id]);
	   if(pag) {
	   console.log("Pagina:",pag, pag[0].title);
	   }
	   const getText = (html) =>{
		const doc = new DOMParser().parseFromString(html, "text/html")
		return doc.body.textContent
	  };
	return (
		<Box maxWidth="lg" sx={{ margin: '60px auto' }} >
		
		{pag &&	<Typography
				className={classes.typoHeading}
				variant="h1"
				sx={{ fontSize: { md: '5rem', xs: '3rem' }, fontWeight: { md: '400', xs: '600' } }}
			>
				{pag[0].title}
			</Typography> }
				<Box  className={classes.box_content}>
						
      				{pag &&		
					              <p
								  dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(pag[0].description),
								  }}
								></p>
					}
						

					</Box>

				


		</Box>
	);
}
