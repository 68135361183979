import axios from "axios";


const token=localStorage.getItem("token");
//console.log(token);
//if(!token) {
//token='';
//}

/*export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
	   'Authorization': `${token}`,
    "Content-type": "application/json"
  }

});*/


//    import axios from "axios";
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
	   'Authorization': `${token}`,
		   "credentials": 'include',
    "Content-type": "application/json"
  },
});
     
//https://medium.com/@j0dev/how-to-change-header-after-token-refresh-using-react-axios-78a2d4dbed1f
http.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization =  token;

  return config;
});

 /*  http.interceptors.request.use((config) => {
      let tokensData = localStorage.getItem("token");
      config.headers["Authorization"] = `${token}`;
      return config;
    });*/
    export default http;
